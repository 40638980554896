import React from "react";
import classnames from "classnames";
// Components
import Helpdesk from "../../auth/company-registration-form/Helpdesk/Helpdesk";
// Constants
import COLORS from "@vahak/core-ui/dist/constants/colors";
// Methods
// Hooks
import useMediaQuery from "@vahak/core-ui/dist/hooks/useMediaQuery";
// Assets
import Styles from "./AssuredHelpDesk.module.scss";
import useRequestCallBack from "../../../services/useRequestCallBack";
interface AssuredHelpDeskProps {
    responsive?: boolean;
    callBackSrc?: string;
}

const AssuredHelpDesk = ({ responsive, callBackSrc }: AssuredHelpDeskProps) => {
    const isMobileScreen = useMediaQuery({ queryType: "mobile" }) && responsive;
    const { CallBackForm, openCallBackReqModal } = useRequestCallBack({ src: callBackSrc });
    const handleClick = () => {
        openCallBackReqModal();
    };
    return (
        <div className={classnames(Styles.main, responsive && Styles.responsive)} onClick={(e) => e.stopPropagation()}>
            <Helpdesk bgColor="transparent" faceColor={COLORS.BLUE} showLabel={!isMobileScreen} onClick={handleClick} />
            {CallBackForm}
        </div>
    );
};

export default AssuredHelpDesk;

import { useQueryClient } from "react-query";
import API from "@vahak/core/dist/_services/const";
import { useVahakMutationQuery } from "@vahak/core/dist/hooks/useVahakQueryClient";
import useVahakHttpClient from "@vahak/core/dist/hooks/useVahakHttpClient";
import { ApiErrorResponseType } from "@vahak/core/dist/custom-types/api-error-response-type";
import queryNames from "../queryNames";
import { LoadBookingInfo } from "@vahak/core/dist/custom-types/common-preloads";

export enum BidType {
    LOAD_BID = 1,
    LORRY_BID = 2
}

interface PostBidParams {
    amount: number;
    type?: number;
    is_vehicle_available?: boolean;
    load_id: number;
    bidder_id: number;
    is_negotiable?: boolean;
    vehicle_number: string;
    bidder_comment: string;
    quantity: number; // lorry capacity
    lorry_id?: number;
}

interface PostLoadBidResponse {
    data: {
        l_id?: number;
        b_id?: number;
        id?: number;
        tap_n_go_info?: LoadBookingInfo;
    };
}

export const usePostLoadBid = () => {
    const { POST } = useVahakHttpClient();

    const postLoadBid = ({
        amount,
        bidder_id,
        is_vehicle_available,
        load_id,
        type = 0,
        bidder_comment,
        is_negotiable = true,
        vehicle_number,
        quantity,
        lorry_id
    }: PostBidParams) => {
        return POST<PostBidParams, PostLoadBidResponse>(API.SUBMIT_LOAD_BID, {
            amount,
            type,
            is_negotiable,
            is_vehicle_available: is_vehicle_available || Boolean(vehicle_number),
            bidder_comment,
            load_id,
            bidder_id,
            quantity,
            vehicle_number,
            lorry_id
        });
    };

    return useVahakMutationQuery<PostLoadBidResponse, ApiErrorResponseType, PostBidParams>(
        queryNames.bid.postLoadBid,
        postLoadBid
    );
};

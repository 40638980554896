import API from "@vahak/core/dist/_services/const";
import { useVahakMutationQuery } from "@vahak/core/dist/hooks/useVahakQueryClient";
import useVahakHttpClient from "@vahak/core/dist/hooks/useVahakHttpClient";
import { QueryNames } from "@vahak/core/dist/_services/hooks/queryNames";
import { ApiErrorResponseType } from "@vahak/core/dist/custom-types/api-error-response-type";

interface LoadPreferredAmountParams {
    id: number;
    preferred_amounts: number;
}

interface UpdateExpectedAmountResponse {
    data: { message: string };
}

export const useLoadPreferredAmount = () => {
    const { POST } = useVahakHttpClient();

    const method = (data: LoadPreferredAmountParams) => {
        return POST<LoadPreferredAmountParams, UpdateExpectedAmountResponse>(API.UPDATE_PREFERRED_AMOUNT, {
            ...data
        });
    };

    return useVahakMutationQuery<UpdateExpectedAmountResponse, ApiErrorResponseType, LoadPreferredAmountParams>(
        QueryNames.load.preferredAmount,
        method
    );
};

import * as Yup from "yup";
import { LorryNumRegExp } from "@vahak/core/dist/constants/regex";

import { LoadBidFormFieldNames } from "../../common/fieldNames";

export const BidFormValidationSchema = Yup.object().shape({
    [LoadBidFormFieldNames.lorryNum]: Yup.string()
        .matches(LorryNumRegExp, "Please enter a valid Lorry Number")
        .min(7, "Please enter a valid Lorry Number")
        .max(11, "Please enter a valid Lorry Number"),
    [LoadBidFormFieldNames.expectedAmount]: Yup.number()
        .positive("")
        .required("Required!")
        .test("min-values-check", "The bid amount is too low.", (value, context: any) => {
            return Number(value) >= 1000;
        })
});

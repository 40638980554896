import React from "react";
import dynamic from "next/dynamic";

import Typography from "@vahak/core-ui/dist/components/Typography";
const Tooltip = dynamic(() => import("../load-lorry-details/components/tool-tip/Tooltip"), { ssr: false });

interface EllipsisTextProps {
    text: string;
    limit: number;
    id?: string;
    tooltipDirection?: "top" | "left" | "bottom" | "right";
}
const EllipsisText = ({ limit = 0, text = "", id, tooltipDirection = "left" }: EllipsisTextProps) => {
    const finalText = text.substring(0, limit);
    const isClipped = finalText.length < text.length;
    return (
        <Tooltip
            appearOnHover
            position={tooltipDirection}
            tooltipToggler={
                <Typography noWrap id={id}>
                    {finalText}
                    {isClipped && "..."}
                </Typography>
            }
            tooltipContent={text}
        />
    );
};

export default EllipsisText;

import React, { useContext } from "react";
import dynamic from "next/dynamic";

//Style
import Styles from "./LoadBidFormNew.module.scss";

//Components
import BidForm from "./bid-form/BidForm";
import { BiddingFormContext } from "../../../BiddingFormContextProvider";
import COLORS from "@vahak/core-ui/dist/constants/colors";
import { IEventTrackerProp } from "../../../types/events";
const ModalOrBottomSheetWrapper = dynamic(() => import("@vahak/core-ui/dist/components/ModalOrBottomSheetWrapper"), {
    ssr: false
});
import PreferredAmountForm from "./bid-form/assured-load/preferred-amount-form/PreferredAmountForm";

export interface lorryDetailsAlreadySelected {
    isLorryAvailable: boolean;
    lorryId: number;
    lorryCapacity: number;
    lorryNum: string;
}
interface LoadBidFormNewProps {
    lorryAlreadySelected?: lorryDetailsAlreadySelected;
    onSuccessLoadBid?: () => void;
    trackEvent?: IEventTrackerProp["trackEvent"];
}

const LoadBidFormNew = ({ lorryAlreadySelected, onSuccessLoadBid, trackEvent }: LoadBidFormNewProps) => {
    const { bidFormConfig } = useContext(BiddingFormContext);

    return (
        <>
            {bidFormConfig.load.open && (
                <ModalOrBottomSheetWrapper
                    isOpen={bidFormConfig.load.open}
                    onToggle={() => {}}
                    showHeader={false}
                    paddedModal={false}
                    modalSize="fit"
                    sheetBackgroundColor={COLORS.GREY_100}
                    paddedSheet={false}
                    sheetBodyHeight={"90vh"}
                    sheetBodyMaxHeight={"90vh"}
                    disableSheetSwipe
                >
                    <BidForm
                        trackEvent={trackEvent}
                        onSuccessLoadBid={onSuccessLoadBid}
                        lorryAlreadySelected={lorryAlreadySelected}
                    />
                </ModalOrBottomSheetWrapper>
            )}
            {bidFormConfig?.loadPreferredAmount?.open &&
                bidFormConfig?.loadPreferredAmount?.id &&
                bidFormConfig?.loadPreferredAmount?.price && (
                    <PreferredAmountForm
                        loadId={bidFormConfig?.loadPreferredAmount?.id}
                        expectedPrice={bidFormConfig?.loadPreferredAmount?.price}
                        isOpen={bidFormConfig?.loadPreferredAmount?.open}
                    />
                )}
        </>
    );
};

export default LoadBidFormNew;

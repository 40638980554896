import Typography from "@vahak/core-ui/dist/components/Typography";
import Flex, { VerticalFlex } from "@vahak/core-ui/dist/layout/Flex";
import React from "react";
import Styles from "./HowAssuredLoadWorksSteps.module.scss";
import AppLink from "../../../../utils/app-link/AppLink";
import AssuredHelpDesk from "../../../../assured-load/helpdesk-wrapper/AssuredHelpDesk";
import { getPriceString } from "@vahak/core-ui/dist/methods";

interface HowAssuredLoadWorksStepsProps {
    title?: string;
    type: "load-bid" | "lorry-bid" | "load-lorry-details";
    showHelp?: boolean;
    bookingAmount?: number;
}

type IListItem = {
    ix?: number;
    content: React.ReactNode;
};

const ListItem = ({ content, ix }: IListItem) => {
    return (
        <span className={Styles.listItem}>
            <span className={Styles.count}>{ix}</span>
            <span className={Styles.content}>{content}</span>
        </span>
    );
};

const getLoadBidList = (amount?: number) => [
    {
        content: "Fixed rate load"
    },
    {
        content: (
            <>
                Pay
                {amount ? (
                    <>
                        <Typography weight="bold"> {getPriceString(amount)}/- </Typography> as&nbsp;
                    </>
                ) : (
                    ` `
                )}
                booking amount
                <AppLink target="_blank" href={"/tapngo-terms-and-conditions"}>
                    &nbsp;(Refund policy)&nbsp;
                </AppLink>
                for confirmation
            </>
        )
    },
    {
        content: "Amount will be refunded if load owner doesn’t confirm"
    }
];

const LoadLorryDetails = [
    "You accept the bid or the lorry person accepts your bid.",
    "You pay the security deposit within the given time showing your interest in this transaction.",
    "Lorry person too will pay the security deposit showing his interest.",
    "If both have paid the security deposit within the given time, your transaction will proceed to next phase.",
    "If the lorry person doesn’t show interest, your security deposit will be refunded within 24 hours and all of your older bids will be reactivated so that you can accept other bid."
];

const HowAssuredLoadWorksSteps = ({
    type,
    showHelp = true,
    title = "How Assured load works?",
    bookingAmount = 0
}: HowAssuredLoadWorksStepsProps) => {
    return (
        <div className={Styles.main}>
            <Flex justifyContent="space-between" alignItems="center">
                <Typography weight="semibold" size="md" mSize="m">
                    {title}
                </Typography>
                {showHelp && <AssuredHelpDesk callBackSrc="assured confirmation" />}
            </Flex>
            <VerticalFlex className={Styles.list}>
                {type === "load-bid" &&
                    getLoadBidList(bookingAmount).map((item, ix) => (
                        <Typography weight="medium" key={ix}>
                            <ListItem content={item?.content} ix={ix + 1} key={ix} />
                        </Typography>
                    ))}

                {type === "load-lorry-details" &&
                    LoadLorryDetails.map((item, ix) => <ListItem content={item} ix={ix + 1} key={ix} />)}
            </VerticalFlex>
        </div>
    );
};

export default HowAssuredLoadWorksSteps;

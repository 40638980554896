import React, { useState } from "react";
// Components
import HowAssuredLoadWorksSteps from "./HowAssuredLoadWorksSteps";
import LineDivider from "@vahak/core-ui/dist/components/LineDivider";
import Typography from "@vahak/core-ui/dist/components/Typography";
import { VerticalFlex } from "@vahak/core-ui/dist/layout/Flex";
import Button from "@vahak/core-ui/dist/components/Button";
// Constants
import COLORS from "@vahak/core-ui/dist/constants/colors";
// Methods
import { getPriceString } from "@vahak/core-ui/dist/methods";
// Hooks
import useMediaQuery from "@vahak/core-ui/dist/hooks/useMediaQuery";
// Assets
import LikeIcon from "@vahak/core/dist/icons/like.svg";
import { useGetBookingAmount } from "../../../../../services/bid/useGetBookingAmount";
import { GA4EventNames, NoSuccessErrorHandler } from "@vahak/core/dist/constants";
import Styles from "./AssuredPaymentConfirmation.module.scss";
import { IEventTrackerProp } from "../../../../../types/events";

interface AssuredPaymentConfirmationProps extends IEventTrackerProp {
    onClickBid?: () => void;
    bidPrice?: number;
    additionalCharges?: number;
    closeBidForm?: () => void;
    disableBtn?: boolean;
    companyId?: number;
}

const AssuredPaymentConfirmation = ({
    onClickBid,
    bidPrice,
    closeBidForm,
    disableBtn,
    additionalCharges,
    companyId,
    trackEvent
}: AssuredPaymentConfirmationProps) => {
    const isMobileScreen = useMediaQuery({ queryType: "mobile" });
    const [bookingAmount, setBookingAmount] = useState(0);
    useGetBookingAmount(
        {
            companyId: companyId!,
            amount: bidPrice
        },
        {
            enabled: !!companyId,
            ...NoSuccessErrorHandler,
            onSuccess(data) {
                setBookingAmount(data?.tap_n_go?.lorry_owner ?? 0);
            },
            refetchOnWindowFocus: false
        }
    );

    const handleRejectAction = () => {
        closeBidForm?.();
        trackEvent?.(GA4EventNames.MARKET_PLACE["bid_ignored"]);
    };

    return (
        <VerticalFlex gap={isMobileScreen ? 10 : 30}>
            <HowAssuredLoadWorksSteps type="load-bid" bookingAmount={bookingAmount} />
            <LineDivider styleType="solid" color={COLORS.GREY_200} />

            <VerticalFlex
                gap={10}
                {...(isMobileScreen && {
                    alignItems: "center"
                })}
            >
                <Typography size="md" mSize="sm">
                    Do you agree with the Fixed Price?
                </Typography>
                <VerticalFlex
                    {...(isMobileScreen && {
                        alignItems: "center"
                    })}
                >
                    <Typography size="l" mSize="md" weight="bold">
                        {getPriceString(bidPrice)}/-
                    </Typography>
                    {!!additionalCharges && (
                        <Typography lineHeight={1} size="sm" mSize="xs" color={COLORS.GREY_500}>
                            (Additional charges{" "}
                            <Typography weight="semibold">{getPriceString(additionalCharges)}</Typography>)
                            <div style={{ marginBottom: 20 }} />
                        </Typography>
                    )}
                </VerticalFlex>

                <div className={Styles.buttonWrapper}>
                    <Button color="success" onClick={onClickBid} disabled={disableBtn}>
                        <span className={Styles.buttonIconWrapper}>
                            <LikeIcon />
                        </span>
                        Yes
                        {!!bookingAmount && <>, Pay {getPriceString(bookingAmount)}</>}
                    </Button>
                    <Button color="danger" onClick={handleRejectAction}>
                        <span className={Styles.buttonIconWrapper}>
                            <LikeIcon />
                        </span>
                        No
                    </Button>
                </div>
            </VerticalFlex>
        </VerticalFlex>
    );
};

export default AssuredPaymentConfirmation;

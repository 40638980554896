import { QueryNames } from "@vahak/core/dist/_services/hooks/queryNames";

import API from "@vahak/core/dist/_services/const";
import { ClientHookOptions, useVahakQuery } from "@vahak/core/dist/hooks/useVahakQueryClient";

import useVahakHttpClient from "@vahak/core/dist/hooks/useVahakHttpClient";
import { generateUrlParams } from "@vahak/core-ui/dist/methods/generateUrlParams";

type BookingAmount = {
    load_owner: number;
    lorry_owner: number;
};

interface BookingAmountResponse {
    data: {
        tap_n_go: BookingAmount;
        spot_load: BookingAmount;
    };
}

interface GetBookingAmountParams {
    amount?: number;
    companyId: number;
}

export const useGetBookingAmount = (
    params: GetBookingAmountParams,
    options: ClientHookOptions<BookingAmountResponse, unknown, BookingAmountResponse["data"]>
) => {
    const { companyId, amount } = params;
    const { GET } = useVahakHttpClient();

    const method = () => {
        const payload = {
            bid_amount: amount,
            c_i: companyId
        };

        const urlString = generateUrlParams(payload);

        return GET<BookingAmountResponse>(`${API.GET_BOOKING_AMOUNT}${urlString}`);
    };

    return useVahakQuery<BookingAmountResponse, unknown, BookingAmountResponse["data"]>(
        [QueryNames.bid.bookingAmount, params],
        method,
        {
            ...options,
            select: (data) => {
                return data?.data ?? [];
            }
        }
    );
};

import React from "react";
import IconWrapper from "@vahak/core-ui/dist/components/IconWrapper";
import Typography, { WeightType } from "@vahak/core-ui/dist/components/Typography";
import Flex from "@vahak/core-ui/dist/layout/Flex";
//
import DistanceIcon from "@vahak/core/dist/standard-icons/distance.svg";
import SizeIcon from "@vahak/core/dist/standard-icons/size.svg";
//
import { DotSymbol } from "@vahak/core-ui/dist/constants/symbol";
import { joinComponents } from "../../../../methods/joinComponents";
import { PAYMENT_TYPE, PAYMENT_TYPE_MAP } from "@vahak/core/dist/constants/payment";
import { filteredArray } from "@vahak/core-ui/dist/methods/array";

interface baseProps {
    type: "payment" | "size";
    fontWeight?: WeightType;
}
interface paymentType extends baseProps {
    type: "payment";
    paymentType: PAYMENT_TYPE;
    averageKM: number;
    advance: number;
}
interface size extends baseProps {
    type: "size";
    length: number;
    breadth: number;
    height: number;
}

type LoadQuickInfoProps = paymentType | size;

const LoadQuickInfo = ({ type, fontWeight, ...props }: LoadQuickInfoProps) => {
    const { averageKM, paymentType, advance } = props as paymentType;
    const { breadth, height, length } = props as size;

    const details = filteredArray([
        averageKM ? `Avg ${Math.ceil(averageKM / 1000)}km travel` : undefined,
        PAYMENT_TYPE_MAP[paymentType]
            ? `${PAYMENT_TYPE_MAP[paymentType] + (advance ? " " + advance + "%" : "")}`
            : undefined
    ]);

    return (
        <Flex display="inline-flex" gap={5} alignItems="center">
            <IconWrapper gap={"5px"} key={"icon"} height={16} width={16}>
                {type === "payment" ? <DistanceIcon /> : <SizeIcon />}
            </IconWrapper>

            {type === "payment" ? (
                joinComponents(
                    details?.map((dt) => (
                        <Typography size="sm" mSize="s" weight={fontWeight ? fontWeight : "medium"} key={dt}>
                            {dt}
                        </Typography>
                    )) ?? [],
                    DotSymbol
                )
            ) : (
                <Typography size="sm" mSize="s" weight={fontWeight ? fontWeight : "medium"}>
                    {length}L x {breadth}B x {height}H (in mts)
                </Typography>
            )}
        </Flex>
    );
};
export default LoadQuickInfo;

import Input, { InputChangeHandler } from "@vahak/core-ui/dist/components/Input";
import ModalOrBottomSheetWrapper, {
    ModalOrBottomSheetWrapperProps
} from "@vahak/core-ui/dist/components/ModalOrBottomSheetWrapper";
import React, { useContext, useState } from "react";
import { Rupee } from "@vahak/core-ui/dist/constants";
import Typography from "@vahak/core-ui/dist/components/Typography";
import { getPriceString, removeNonNumeric } from "@vahak/core-ui/dist/methods";
import { VerticalFlex } from "@vahak/core-ui/dist/layout/Flex";
import Button from "@vahak/core-ui/dist/components/Button";
import { useLoadPreferredAmount } from "../../../../../../hooks/assured-load/useLoadPreferredAmount";
import { toast } from "@vahak/core/dist/components/toast/toast";
import { BID_ACTIONS, BiddingFormContext } from "../../../../../../BiddingFormContextProvider";
import useMediaQuery from "@vahak/core-ui/dist/hooks/useMediaQuery";
import COLORS from "@vahak/core-ui/dist/constants/colors";

interface PreferredAmountFormProps extends Partial<ModalOrBottomSheetWrapperProps> {
    loadId: number;
    expectedPrice: number;
}

const PreferredAmountForm = (props: PreferredAmountFormProps) => {
    const { mutateAsync: updatePreferredAmount } = useLoadPreferredAmount();
    const { dispatchBid } = useContext(BiddingFormContext);
    const isMobileScreen = useMediaQuery({ queryType: "mobile" });

    const name = "preferred_price";
    const [price, setPrice] = useState("");

    const handleChange: InputChangeHandler = (e) => {
        setPrice(removeNonNumeric(e.target.value).slice(0, 7));
    };
    const resetForm = () => {
        dispatchBid({
            type: BID_ACTIONS.LOAD_PREFERRED_AMT,
            payload: { id: 0, open: false }
        });
    };

    const submitData = () => {
        return new Promise((resolver) => {
            updatePreferredAmount(
                {
                    id: props?.loadId,
                    preferred_amounts: Number(price)
                },
                {
                    onSuccess: () => {},
                    onError: (err) => {
                        toast.error(err.message);
                    },
                    onSettled: () => {
                        resetForm();
                        resolver(0);
                    }
                }
            );
        });
    };

    const canContinue = !!price && Number(price) > Number(props?.expectedPrice);

    return (
        <ModalOrBottomSheetWrapper
            onToggle={resetForm}
            isOpen={props?.isOpen}
            titleText="Do you want to suggest a new price?"
            sheetTitleTextSize="m"
            modalSize="fit"
        >
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    submitData();
                }}
                style={{ width: isMobileScreen ? "100%" : 560 }}
            >
                <VerticalFlex gap={60}>
                    <VerticalFlex gap={16}>
                        <Input
                            name={name}
                            id={name + "_txtf"}
                            value={(Number(price) ? Number(price)?.toLocaleString("en-in") : "") as any}
                            type="number"
                            placeholder=""
                            inputMode="numeric"
                            onChange={handleChange}
                            prefixNode={
                                <Typography color={COLORS.BLACK} weight="medium">
                                    {Rupee}
                                </Typography>
                            }
                            weight="medium"
                            autoFocus
                        />
                        <Typography size="xs" weight="medium">
                            Enter your Expected price greater than
                            <Typography weight="bold"> {getPriceString(props?.expectedPrice)}/-</Typography>
                        </Typography>
                    </VerticalFlex>
                    <Button type="submit" blockBtn onClick={submitData} disabled={!canContinue}>
                        Suggest Price
                    </Button>
                </VerticalFlex>
            </form>
        </ModalOrBottomSheetWrapper>
    );
};

export default PreferredAmountForm;
